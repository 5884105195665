function localizeAssets() {
  if (i18next.language != 'da') {
    $('.testimonials').remove();
    $('.customers').remove();
    $('.footer .cvr-number').remove();
    $('a[data-target="#demoModal"]').remove();
  }

  $('[data-i18n-img-src]').each(function(idx, elm) {
    var $elm = $(elm);
    var lng = i18next.language.match(/.*?(?=-|$)/i)[0];
    var url = $elm.data('i18nImgSrc').replace('{locale}', lng);

    $elm.attr('src', url);
  });
}

$(function() {
  var pathname = location.pathname.split('/')[1];

  if (pathname.length > 0) {
    $('nav .navbar-nav a[href^="/' + pathname + '"]').addClass('active');
  }

  i18next.on('loaded', function() {
    localizeAssets();
  });

  $('a.more-link').click(function(){
    $('html, body').animate({
        scrollTop: $( $.attr(this, 'href') ).offset().top
    }, 500);
    return false;
  });

  $('.thumbnail .circle').circleProgress({
    startAngle: -Math.PI / 2,
    size: 1000,
    emptyFill: '#FFF',
    fill: {
      gradient: [ '#fcb034' ]
    }
  });

  $('.signup-form-container form').submit(function(e) {
    e.preventDefault();

    var value = $('.contact-input').val().trim();

    if (value !== '') {
      $.post(
        'https://www.autobutler.dk/api/v2/mechanics/support_followups',
        {
          website: 'autobutler',
          emailOrPhoneNumber: value,
          locale: i18next.language
        }
      ).done(function() {
        $('.signup-form-container form').hide();
        $('.success-message').removeClass('hide');
        history.pushState(null, null, '#sign-up-success');
      });
    }
  });

  if (document.getElementById('did-you-know')) {
    var waypoint = new Waypoint({
      element: document.getElementById('did-you-know'),
      handler: function(direction) {
        $('.thumbnail .circle').each(function() {
          $(this).circleProgress({
            value: $(this).data('value')
          });
        });

        this.destroy()
      },
      offset: '50%'
    });
  }
});
